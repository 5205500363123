import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import * as blogStyles from './blog.module.scss'

import Layout from '../components/layout'
import Head from '../components/head'

const BlogPage = () => {
	const data = useStaticQuery(graphql`
	query {
		allContentfulBlogPost (
		  sort: {
			fields: publishDate,
			order: DESC
		  }
		) {
		  edges {
			node {
			  title
			  slug
			  publishDate(formatString: "MMMM Do, YYYY")
			}
		  }
		}
	  }
	  `)

	const blogItems = data.allContentfulBlogPost.edges.map((item) =>
		<li className={blogStyles.post}>
			<Link to={`/blog/${item.node.slug}`}>
				<h2>{item.node.title}</h2>
				<p>{item.node.publishDate}</p>
			</Link>
		</li>
	)
	// console.log(blogItems)
	return (
		<Layout>
			<Head title="Blog" />
			<h1>Blog</h1>
			<ol className={blogStyles.posts}>{blogItems}</ol>
		</Layout>
	)
}

export default BlogPage